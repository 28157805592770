<template>
  <!-- <div>
    <h4 class="sof-text-title">{{ title }}</h4>
    <p class="mt-1 pb-6 text-secondary-text">{{ text }}</p>
  </div> -->
  <div v-if="currentGroup === 'BASIC_ADDRESS_FIELDS'">
    <h4 class="sof-text-title">{{ $t('SendMoneyAdditionalDetails.OneMoreThing').value }}</h4>
    <p class="mt-1 pb-6 text-secondary-text">
      {{ $t('SendMoneyAdditionalDetails.WeNeedMoreInformationFromYou').value }}
    </p>
  </div>
  <div v-else-if="currentGroup === 'SOURCE_OF_FUNDS'">
    <h4 class="sof-text-title">
      {{ $t('SendMoneyAdditionalDetails.WeNeedToAskTitleText').value }}
    </h4>
    <p class="mt-1 pb-6 text-secondary-text">
      {{ $t('SendMoneyAdditionalDetails.SourceOfFundsQuestonText').value }}
    </p>
  </div>
  <div v-else-if="currentGroup === 'RELATIONSHIP_FIELDS'">
    <h4 class="sof-text-title">
      {{ $t('SendMoneyAdditionalDetails.WeNeedToAskTitleText').value }}
    </h4>
    <p class="mt-1 pb-6 text-secondary-text">
      {{ $t('SendMoneyAdditionalDetails.RelationshipQuestionText').value }}
    </p>
  </div>
  <div v-else-if="currentGroup === 'PHONE_GROUP' || currentGroup === 'recipient'">
    <h4 class="sof-text-title">
      {{ $t('SendMoneyAdditionalDetails.RecipientPhoneTitleText').value }}
    </h4>
    <p class="mt-1 pb-6 text-secondary-text">
      {{
        $t('SendMoneyAdditionalDetails.RecipientPhoneQuestionText', {
          recipientName: recipient.fullName,
        }).value
      }}
    </p>
  </div>
  <div v-else-if="currentGroup === 'RiaQuestion'">
    <h4 class="sof-text-title">
      {{ $t('SendMoneyAdditionalDetails.MoreAboutYouTitleText').value }}
    </h4>
    <p class="mt-1 pb-6 text-secondary-text">
      {{ filteredFields[0].fieldDefinition.text }}
    </p>
  </div>
  <div v-else>
    <!-- Empty for now -->
  </div>
</template>

<script scoped>
import { useI18nStore } from '@galileo/stores'
export default {
  name: 'AdditionalDetailsQuestionDescription',
  props: {
    currentGroup: {
      type: String,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    filteredFields: {
      type: [],
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()

    return { $t }
  },
}
</script>

<style>
.sof-text {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.sof-text-title {
  font-size: 16px;
  font-weight: 500;
}
</style>
