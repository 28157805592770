<template>
  <div class="file-number-wrapper" v-if="showPassportFileNumber">
    <AppInputText
      v-model="field.value"
      :label="$t('SendMoneyAdditionalDetailsFormFieldParser.PassportFileNumberLabel').value"
      :placeholder="
        $t('SendMoneyAdditionalDetailsFormFieldParser.PassportFileNumberPlaceholder').value
      "
      :validation="field.validation"
      class="file-number-input"
    >
    </AppInputText>

    <div class="info">
      <AppLink class="infoButton" @click="showFileNumberInfo">
        {{ $t('SendMoneyAdditionalDetailsFormFieldParser.WhereToFindFileNumber').value }}
      </AppLink>
    </div>

    <AppDialog class="file-nr-info-wrapper" :value="showFindingFileNumber">
      <template #header>
        <AppModalHeader>
          <template #right>
            <AppBackButton @click="closeFileNumberInfo" class="find-file-number-modal-back" />
          </template>
        </AppModalHeader>
      </template>

      <div class="flex image-styling">
        <IconInfoAlt />
      </div>
      <h1 class="find-file-number-title">
        {{ $t('PassportFileNumber.FindingFileNumberTitle').value }}
      </h1>
      <ul class="bullet-list">
        <li>
          {{ $t('PassportFileNumber.FindingFileNumberTip1').value }}
        </li>
        <li>{{ $t('PassportFileNumber.FindingFileNumberTip2').value }}</li>
      </ul>
    </AppDialog>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { AppInputText, AppLink, AppBackButton, AppDialog, AppModalHeader } from '@oen.web.vue2/ui'
import { computed, ref } from '@vue/composition-api'
import { IconInfoAlt } from '@galileo/assets/icons/vue'

export default {
  name: 'PassportFileNumberInputText',
  components: {
    AppInputText,
    AppLink,
    AppBackButton,
    AppDialog,
    AppModalHeader,
    IconInfoAlt,
  },
  props: {
    groupFields: {
      type: [],
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const showFindingFileNumber = ref(false)

    const showPassportFileNumber = computed(() => {
      const passportFileNumberField = props.groupFields.find((field) => {
        return field.id === 'passportFileNumber'
      })

      passportFileNumberField.treatAsVisible = true
      passportFileNumberField.isRequired = true
      passportFileNumberField.validation.required.$touch()

      return true
    })

    const showFileNumberInfo = () => {
      showFindingFileNumber.value = true
    }

    const closeFileNumberInfo = () => {
      showFindingFileNumber.value = false
    }

    return {
      showPassportFileNumber,
      $t,
      showFileNumberInfo,
      showFindingFileNumber,
      closeFileNumberInfo,
    }
  },
}
</script>

<style scoped>
.file-number-wrapper {
  .file-number-input {
    ::v-deep .input-label {
      @apply tracking-normal text-icon-primary text-base font-semibold;
    }
  }

  .file-nr-info-wrapper {
    .card-header,
    .card-content {
      padding: 0px !important;
    }
  }

  ::v-deep .dialog {
    header {
      @apply pl-12 pt-12 pr-12;
      .card-header-left,
      .card-header-title {
        @apply hidden;
      }
      .card-header-right {
        button {
          @apply w-5 h-5;
        }
        .button-inner {
          @apply w-full h-full;
        }
      }
    }

    .dialog--card.card .card-content {
      @apply p-12 pt-3;

      .image-styling {
        svg {
          @apply w-7 h-7;
        }
      }
    }
  }

  .align-sub {
    ::v-deep svg {
      vertical-align: sub;
    }
  }
  .card-warning {
    @apply py-2 px-3 mt-6 mb-6 leading-5 text-sm rounded font-normal;
    color: theme('colors.blue.default');
  }
  .info {
    @apply mt-9 text-center;
    margin-bottom: -20px;
  }
  .infoButton.link {
    @apply font-medium text-base leading-6;
    color: var(--theme-color-primary, theme('colors.blue.default'));
  }
  .bullet-list {
    @apply text-base leading-6 text-left mt-5 text-gray-text list-none;
    li {
      @apply relative tracking-normal pl-6;
      &:last-child {
        @apply mt-4;
      }
    }
  }
  .bullet-list li::before {
    @apply absolute left-0;
    content: '•';
    color: #8d8d8d;
    font-size: 18px;
  }
  .find-file-number-header {
    @apply p-0;
  }
  .find-file-number-modal-back {
    @apply relative top-0 right-0 w-3 h-3;
  }
  .find-file-number-title {
    @apply p-0 mt-5;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
}
</style>
