


















































import { useI18nStore, useOccupationStore, useResourcesStore } from '@galileo/stores'
import { GenericField } from '@galileo/models/Resources/interfaces/SystemFields'
import { IndustryType } from '@galileo/models/Industry/industryType'
import { IndustryCategory } from '@galileo/models/Industry/industryCategory'
import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'
import { ref, onBeforeMount, defineComponent, PropType, watch } from '@vue/composition-api'
import Field from '@galileo/models/DynamicField/Field'

export default defineComponent({
  name: 'OccupationCustomQuestions',
  components: {
    AppInputText,
    AppInputDropdown,
  },

  props: {
    formFields: {
      type: [] as PropType<Array<any>>,
      required: true,
    },
  },
  emits: ['isValid'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const occupationStore = useOccupationStore()
    const resourcesStore = useResourcesStore()

    const subIndustries = ref<Array<IndustryType>>([])
    const occupations = ref<Array<GenericField>>([])
    const industries = ref<Array<IndustryCategory>>([])
    const hideSubIndustries = ref(true)
    const showIndustries = ref(false)
    const showOccupationFreeText = ref(false)
    const showFreeTextHint = ref(true)
    const selectedIndustry = ref(0)
    const selectedSubIndustry = ref(0)
    const selectedFreeText = ref('')
    const occupationField = ref()

    onBeforeMount(() => {
      occupations.value = resourcesStore.getOccupations
      occupationField.value = props.formFields.find((field) => field.id == 'occupation')
      emit('isValid', false)
    })

    const selectedOccupationChange = async (occupationValue: Field) => {
      const occupation: GenericField | undefined = findOccupation(occupationValue.value)
      showOccupationFreeText.value = false

      if (isMatch(occupation)) {
        emit('isValid', false)
        showFreeTextHint.value = false
        showIndustries.value = true
        const categories = await occupationStore.getIndustries()
        industries.value = categories
      } else {
        emit('isValid', true)
        showFreeTextHint.value = true
        showIndustries.value = false
        hideSubIndustries.value = true
        if (occupation?.value === 'OTHERS_6') {
          showFreeTextHint.value = false
          showOccupationFreeText.value = true
          emit('isValid', false)
        }
      }
    }

    watch(selectedFreeText, (val: string) => {
      const freeTextField = props.formFields.find((field) => field.id == 'occupationFreeText')
      const regex = freeTextField.fieldDefinition.validations.find(
        (v: { type: string }) => v.type === 'Regex'
      )
      const validationFreeTextRegex = new RegExp(regex.value)
      const isFreeTextValid = validationFreeTextRegex.test(val)

      emit('isValid', isFreeTextValid)
      freeTextField.value = val
    })

    const selectedIndustryChange = async () => {
      emit('isValid', false)
      const types = await occupationStore.getSubIndustries()
      const allSubIndustries = types

      hideSubIndustries.value = false
      subIndustries.value = filterSubIndustries(allSubIndustries)

      //add value to form field
      const industryField = props.formFields.find((field) => field.id == 'industry')
      industryField.value = selectedIndustry.value.toString()
    }

    const selectedSubIndustryChange = () => {
      //add value to form field
      emit('isValid', true)
      const subIndustryField = props.formFields.find((field) => field.id == 'subIndustry')
      subIndustryField.value = selectedSubIndustry.value.toString()
    }

    function findOccupation(occupationValue: string): GenericField | undefined {
      return occupations?.value.find((item) => item.value === occupationValue)
    }

    function isMatch(occupation: GenericField | undefined) {
      //check if occupation matches the list of industry matched occupations stored in fieldDefinition.options
      const occupationField = props.formFields.find((field) => field.id == 'occupation')

      const matchedOccupationList = occupationField.fieldDefinition.options

      return matchedOccupationList.includes(occupation?.value)
    }

    function filterSubIndustries(allSubIndustries: Array<IndustryType>) {
      return allSubIndustries.filter((item) => item.industryCategoryId == selectedIndustry.value)
    }

    return {
      industries,
      selectedIndustryChange,
      showIndustries,
      selectedIndustry,
      subIndustries,
      hideSubIndustries,
      selectedSubIndustry,
      occupations,
      selectedOccupationChange,
      showOccupationFreeText,
      selectedSubIndustryChange,
      selectedFreeText,
      showFreeTextHint,
      $t,
      occupationField,
    }
  },
})
