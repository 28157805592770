<template>
  <div>
    <OccupationCustomQuestionsVue
      v-if="field.id === 'occupation'"
      :form-fields="formFieldsAdditional"
      @isValid="(val) => isValid(val)"
    />

    <AdditionalDetailsAppInputPhone
      v-if="shouldShowUserPhoneQuestion()"
      :field="field"
      :form-fields="formFieldsAdditional"
      @isValid="(val) => isValid(val)"
    />

    <AdditionalDetailsAppInputPhone
      v-if="field.id === 'ria_bene_mobile'"
      :field="field"
      :form-fields="formFieldsAdditional"
      @isValid="(val) => isValid(val)"
    />

    <AppInputRadioButtons
      v-else-if="field.id === 'riaQuestion_461255'"
      name="selectedRiaAnswer"
      analytics-name="ria-questions-radio-buttons"
      :options="availableRiaAnswers"
      left
      @input="(val) => $emit('enterRiaAnswer', val)"
    >
      <template #radio-button-content="{ option }">
        <span class="label">
          {{ option.label }}
        </span>
        <span v-if="option.labelDescription" class="option-label-description">
          {{ option.labelDescription }}
        </span>
      </template>
    </AppInputRadioButtons>

    <LicenceCardNumberInputText
      v-else-if="field.id === 'licenceCardNumber'"
      :field="field"
      :group-fields="groupFields"
    />

    <PassportFileNumberInputText
      v-else-if="field.id === 'passportFileNumber'"
      :field="field"
      :group-fields="groupFields"
    />
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import { computed } from '@vue/composition-api'

import { AppInputRadioButtons } from '@oen.web.vue2/ui'

import AdditionalDetailsAppInputPhone from '@galileo/components/Views/SendMoney/AdditionalDetailsAppInputPhone/AdditionalDetailsAppInputPhone'

import LicenceCardNumberInputText from '@galileo/components/Views/SendMoney/AdditionalDetails/CustomQuestions/LicenceCardNumberInputText'
import PassportFileNumberInputText from '@galileo/components/Views/SendMoney/AdditionalDetails/CustomQuestions/PassportFileNumberInputText'

import OccupationCustomQuestionsVue from '@galileo/components/Views/SendMoney/AdditionalDetails/CustomQuestions/OccupationCustomQuestions'

export default {
  name: 'CustomQuestionBasedByField',
  components: {
    AppInputRadioButtons,
    LicenceCardNumberInputText,
    AdditionalDetailsAppInputPhone,
    PassportFileNumberInputText,
    OccupationCustomQuestionsVue,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    groupFields: {
      type: [],
      required: true,
    },
    formFieldsAdditional: {
      type: [],
      required: true,
    },
    currentGroup: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    // ------- Ria input Yes No
    const availableRiaAnswers = computed(() => [
      {
        label: $t('PageSendMoneyPepQuestions.ButtonYes').value,
        value: 'Yes',
        labelDescription: '',
      },
      {
        label: $t('PageSendMoneyPepQuestions.ButtonNo').value,
        value: 'No',
        labelDescription: '',
      },
    ])

    const shouldShowUserPhoneQuestion = () => {
      const prefixAsked = props.formFieldsAdditional.find((field) => field.id === 'mobilePrefix')
      const phoneAsked = props.formFieldsAdditional.find((field) => field.id === 'mobilePhone')
      return (
        (prefixAsked && props.field.id == 'mobilePrefix') ||
        (phoneAsked && props.field.id == 'mobilePhone' && !prefixAsked)
      )
    }

    const isValid = (value) => {
      emit('isValid', value)
    }

    return { $t, availableRiaAnswers, isValid, shouldShowUserPhoneQuestion }
  },
}
</script>

<style scoped>
.card-number {
  ::v-deep .tooltip-wrapper {
    @apply relative text-gray-dark mx-1;
    bottom: -6px;

    > div:not(.tooltip-tip) {
      @apply flex justify-center;
    }
  }
}

::v-deep .input-radio-button {
  z-index: 9999;
}
</style>
