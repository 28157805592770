<template>
  <div v-if="currentGroup">
    <div v-if="currentGroup === 'IdDoc'">
      <div v-if="isLabelFileNumber">
        {{ $t('AdditionalInformationModal.Title').value }}
      </div>
      <div v-else>
        {{ $t('SendMoneyAdditionalDetails.AddYourIdText').value }}
      </div>
      
      <!-- Add your ID -->
    </div>
    <div v-else-if="currentGroup === 'PHONE_GROUP'">
      <div v-if="isLabelRecipientAddress">
        {{ $t('SendMoneyAdditionalDetails.AddRecipientsAddress').value }}
      </div>
      <div v-else>
        {{ $t('SendMoneyAdditionalDetails.AddPhoneNumberText').value }}
      </div>

      <!-- Add recipient phone number -->
    </div>
    <div v-else-if="currentGroup === 'CUSTOMER_PHONE_GROUP'">
      {{ $t('PageSendMoneyAdditionalDetails.AddMobilePhoneTitle').value }}
      <!-- Add customer phone number -->
    </div>
    <div v-else-if="currentGroup === 'BASIC_ADDRESS_FIELDS'">
      {{ $t('PageSendMoneyAdditionalDetails.AddYourAddress').value }}
      <!-- Add customer address -->
    </div>
    <div v-else-if="currentGroup === 'OtherInfo' && containsOnlyQuestionById('dateOfBirth')">
      {{ $t('PageSendMoneyAdditionalDetails.AddYourDateOfBirth').value }}
      <!-- Add your date of birth -->
    </div>
    <div v-else>{{ $t('PageSendMoneyAdditionalDetails.Title').value }}</div>
  </div>
  <div v-else>{{ $t('PageSendMoneyAdditionalDetails.Title').value }}</div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { computed } from '@vue/composition-api'
export default {
  name: 'AdditionalDetailsHeaderTitle',
  props: {
    currentGroup: {
      type: String,
      required: false,
    },
    filteredFields: {
      type: [],
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const isLabelRecipientAddress = computed(() => {
      return props.filteredFields.find((field) => field.id === 'ria_bene_addressLine1')
    })

    const isLabelFileNumber = computed(() => {
      return props.filteredFields.find((field) => field.id === 'passportFileNumber')
    })

    const containsOnlyQuestionById = (questionid) => {
      if (!props.filteredFields) {
        return false
      }

      let contains = props.filteredFields.find((field) => field.id == questionid)
      return contains && props.filteredFields.length === 1
    }

    return { $t, containsOnlyQuestionById, isLabelRecipientAddress, isLabelFileNumber }
  },
}
</script>

<style></style>
