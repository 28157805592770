import { render, staticRenderFns } from "./PassportFileNumberInputText.vue?vue&type=template&id=149a60a0&scoped=true&"
import script from "./PassportFileNumberInputText.vue?vue&type=script&lang=js&"
export * from "./PassportFileNumberInputText.vue?vue&type=script&lang=js&"
import style0 from "./PassportFileNumberInputText.vue?vue&type=style&index=0&id=149a60a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.2_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cache-loader@4.1.0_webp_dbc1f76d1931dbdfddfdff69f826e4c8/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "149a60a0",
  null
  
)

export default component.exports