import { ref, computed } from '@vue/composition-api'

import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useSendMoneyStore, useAnalyticsStore } from '@galileo/stores'

export default function (
  formFieldsAdditional,
  hasFormFieldsAdditional,
  riaQuestionField,
  isAnswerValid
) {
  const analyticsStore = useAnalyticsStore()
  const groupIndex = ref(0)
  const isLastGroup = ref(false)
  const isFirstGroup = ref(true)
  const currentGroup = ref('')
  const sendMoneyStore = useSendMoneyStore()

  const fieldGroups = computed(() => {
    let groupsContainer = []
    const additionalFields = sendMoneyStore.additionalFields
    additionalFields.forEach((field) => {
      groupsContainer.push(field.group)
    })

    if (groupsContainer.includes('RiaQuestion')) {
      riaQuestionField.value = formFieldsAdditional.value.find((field) =>
        field.id.includes('riaQuestion')
      )
      isAnswerValid.value = true
    }
    return [...new Set(groupsContainer)]
  })

  const sortedGroupFields = computed(() => {
    const groupFields = formFieldsAdditional.value.filter(
      (field) => field.fieldDefinition.group === fieldGroups.value[groupIndex.value]
    )

    isLastGroup.value = fieldGroups.value.length === groupIndex.value + 1
    isFirstGroup.value = groupIndex.value === 0

    currentGroup.value = fieldGroups.value[groupIndex.value]

    // ----------------------------
    // Sorting the fields for type of identification (idType) in the order defined by sortingArrayy
    const sortingArray = [
      'idType',
      'licenceIssuer',
      'licenceNumber',
      'passportNumber',
      'passportIssuer',
      'passportFileNumber',
      'licenceCardNumber',
      'idExpiryDate',
      'occupation',
      'industry',
      'subIndustry'
    ]

    groupFields.sort((a, b) => {
      if (sortingArray.includes(a.id) && sortingArray.includes(b.id)) {
        return sortingArray.indexOf(a.id) - sortingArray.indexOf(b.id)
      } else {
        return 0
      }
    })
    return groupFields
  })

  const isGroupValid = computed(() => {
    let anyInvalid = sortedGroupFields.value.find((field) => {
      return field.validation.$anyInvalid
    })

    let dateFields = sortedGroupFields.value.filter((field) => {
      return field.fieldDefinition.type === 'date' && !field.hidden
    })

    return !anyInvalid
  })

  const goToNextGroup = () => {
    groupIndex.value++
    checkEvents()
  }

  const goToPreviousGroup = () => {
    groupIndex.value--
  }

  const sentEvents = []
  const checkEvents = () => {
    const trackEvents = {}
    if (hasFormFieldsAdditional.value) {
      for (const formField of formFieldsAdditional.value) {
        const { id, value } = formField
        if (!value) {
          continue
        }

        let trackEvent = null
        if (
          id === 'idType' ||
          id === 'idExpiryDate' ||
          id === 'passportNumber' ||
          id === 'passportIssuer' ||
          id === 'licenceNumber' ||
          id === 'licenceIssuer'
        ) {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_ID_INFORMATION_COMPLETED,
            traits: {},
          }
        } else if (id === 'mobileNumber' || id === 'mobilePrefix') {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_ID_MOBILE_NUMBER_COMPLETED,
            traits: {},
          }
        } else if (id === 'relationToBeneficiary') {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_RELATIONSHIP_TO_BENEFICARY_COMPLETED,
            traits: {},
          }
        } else if (id.indexOf('sourceOfFunds') === 0) {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_SOURCE_OF_FUNDS_COMPLETED,
            traits: {},
          }
        } else if (
          id === 'countryOfBirth' ||
          id === 'nationality' ||
          id === 'occupation' ||
          id === 'socialSecurityNumber'
        ) {
          trackEvent = {
            event: SEGMENT_EVENTS.EDD_PERSONAL_INFORMATION_COMPLETED,
            traits: {},
          }
        }

        if (
          trackEvent &&
          trackEvent.event &&
          !trackEvents[trackEvent.event] &&
          sentEvents.indexOf(trackEvent.event) === -1
        ) {
          trackEvents[trackEvent.event] = trackEvent
        }
      }
    }

    for (const name in trackEvents) {
      const trackEvent = trackEvents[name]
      analyticsStore.track(trackEvent)
      sentEvents.push(name)
    }
  }

  return {
    sortedGroupFields,
    fieldGroups,
    groupIndex,
    isLastGroup,
    isFirstGroup,
    currentGroup,
    isGroupValid,
    goToNextGroup,
    goToPreviousGroup,
    checkEvents,
  }
}
