<template>
  <AppInputText
    v-if="showLicenceCardNumber"
    v-model="field.value"
    :label="$t('SendMoneyAdditionalDetailsFormFieldParser.LicenceCardNumberLabel').value"
    :placeholder="
      $t('SendMoneyAdditionalDetailsFormFieldParser.LicenceCardNumberPlaceholder').value
    "
    :validation="field.validation"
    class="card-number"
  >
    <template #labelRight>
      <XeTooltip activation="click" side="bottom">
        <template #wrappedElement>
          <AppIcon name=" ">
            <IconInformation />
          </AppIcon>
        </template>
        <template #tooltipContent>
          {{ $t('AdditionalInformationModal.CardNumberTooltipText').value }}
        </template>
      </XeTooltip>
    </template>
  </AppInputText>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { AppInputPhone, AppInputRadioButtons, AppInputText, AppIcon } from '@oen.web.vue2/ui'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import { computed } from '@vue/composition-api'
import { IconInformation } from '@oen.web.vue2/icons'

export default {
  name: 'LicenceCardNumberInputText',
  components: {
    AppInputPhone,
    AppInputRadioButtons,
    AppInputText,
    AppIcon,
    XeTooltip,
    IconInformation,
  },
  props: {
    groupFields: {
      type: [],
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    //-------------------- IdType Licence Card Number

    const showLicenceCardNumber = computed(() => {
      const typeSelectionField = props.groupFields.find((field) => {
        return field.id === 'idType'
      })

      //if there is no typeSelectionField means the field is on it's own
      const isIdTypeSelected = !typeSelectionField || typeSelectionField.value === 'DriverLicence'

      const licenceCardNumberField = props.groupFields.find((field) => {
        return field.id === 'licenceCardNumber'
      })

      if (isIdTypeSelected) {
        licenceCardNumberField.treatAsVisible = true

        licenceCardNumberField.isRequired = true
        licenceCardNumberField.validation.required.$touch()
        return true
      } else {
        licenceCardNumberField.treatAsVisible = false

        licenceCardNumberField.isRequired = false
        licenceCardNumberField.validation.required.$touch()
        return false
      }
    })

    return { showLicenceCardNumber, $t }
  },
}
</script>

<style></style>
