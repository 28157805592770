<template>
  <AppCard class="send-money-additional-details-wrapper">
    <template #header>
      <AppCardHeader>
        <template #left>
          <AppBackButton
            v-if="isFirstGroup"
            class="back-button"
            name="Go Back"
            analytics-name="send-money-additional-back"
            icon="<"
            @click="goBackOneStep"
          />
          <AppBackButton
            v-if="!isFirstGroup"
            class="back-button"
            name="Go Back"
            analytics-name="send-money-additional-back"
            icon="<"
            @click="goToPreviousGroup"
          />
        </template>
        <AdditionalDetailsHeaderTitle
          :current-group="currentGroup"
          :filtered-fields="sortedGroupFields"
          class="send-money-additional-title"
        />
      </AppCardHeader>
    </template>

    <div v-if="hasQuestions">
      <AppAlert theme="blue" class="mb-6">
        <p class="flex warning-box">
          <IconExclamationMark class="exclamation-mark" />
          <span class="ml-2">
            {{ $t('SendMoneyAdditionalDetailsFormFieldParser.NeedMoreInfoText').value }}
          </span>
        </p>
      </AppAlert>
      <AdditionalDetailsQuestionDescription
        :current-group="currentGroup"
        :recipient="recipient"
        :filtered-fields="sortedGroupFields"
      />

      <div v-if="currentGroup === 'BASIC_ADDRESS_FIELDS'">
        <AppInputCountry
          v-model="selectedCountry"
          :countries="countries"
          :disabled="true"
          :label="$t('PageRecipients.CountryOfResidence').value"
        />
        <XeAddressSearch
          :dropdown-label="$t('ModifyRecipientModal.SearchAddress').value"
          :search-promise-lazy="searchPromiseLazy"
          :get-address-details-promise-lazy="getAddressDetailsPromiseLazy"
          :show-manual-selection="false"
          @select="onSelectAddress"
        />
      </div>

      <div v-if="hasBasicEddQuestions">
        <IndustryEddForm @on-subindustry-select="onSubIndustrySelect" />

        <AppButton
          :loading="loading"
          :disabled="!subIndustryId"
          analytics-name="send-money-additional-details-continue"
          @click="onSubmitBasicEdd"
        >
          {{ $t('PageSendMoneyAdditionalDetails.ButtonContinue').value }}
        </AppButton>
      </div>
      <template v-else>
        <div v-for="field in sortedGroupFields" :key="field.id">
          <CustomQuestionBasedByField
            :key="field.id"
            :field="field"
            :group-fields="sortedGroupFields"
            :form-fields-additional="formFieldsAdditional"
            :current-group="currentGroup"
            @enterRiaAnswer="enterRiaAnswer"
            @isValid="fieldValidationListener"
          />

          <AppDynamicForm :fields="[field]" />
        </div>
      </template>

      <div v-if="hasFormFieldsRecipient">
        <p class="pb-6">
          {{ $t('PageSendMoneyAdditionalDetails.Description2').value }}
        </p>
        <AppDynamicForm :fields="formFieldsRecipient" />
      </div>
    </div>
    <div v-else>
      <AppSpinnerBig loading />
    </div>

    <template #footer>
      <AppCardFooter>
        <AppButton
          v-if="isLastGroup && !hasBasicEddQuestions"
          :loading="loading"
          :disabled="
            isFormInvalidAdditional ||
            isFormInvalidRecipient ||
            formValidationAdditional.$anyInvalid ||
            invalidField
          "
          analytics-name="send-money-additional-details-continue"
          @click="submit()"
        >
          <!-- (hasPhoneField && validation.phone.$anyInvalid) -->
          {{ $t('PageSendMoneyAdditionalDetails.ButtonContinue').value }}
        </AppButton>
        <AppButton
          v-if="!isLastGroup && hasQuestions"
          :disabled="!isGroupValid || invalidField"
          @click="goToNextGroup"
        >
          <!-- (hasPhoneField && validation.phone.$anyInvalid) -->
          {{ $t('PageSendMoneyAdditionalDetails.ButtonContinue').value }}
          <!-- Continue -->
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { onBeforeMount, ref, reactive, computed, toRef } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import { useRouter } from '@galileo/composables/useRouter'
import { useSendMoneyAdditionalDetailsForm } from '@galileo/forms/SendMoneyAdditionalDetailsForm'
import { useRecipientForm } from '@galileo/forms/RecipientForm'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  useMediaQuery,
  AppButton,
  AppCard,
  AppDynamicForm,
  AppCardFooter,
  AppCardHeader,
  AppBackButton,
  AppSpinnerBig,
  AppIcon,
  AppInputCountry,
  AppAlert,
} from '@oen.web.vue2/ui'

import { IconExclamationMark } from '@galileo/assets/icons/vue'

import AdditionalDetailsHeaderTitle from '@galileo/components/Views/SendMoney/AdditionalDetails/AdditionalDetailsHeaderTitle'
import AdditionalDetailsQuestionDescription from '@galileo/components/Views/SendMoney/AdditionalDetails/AdditionalDetailsQuestionDescription'
import CustomQuestionBasedByField from '@galileo/components/Views/SendMoney/AdditionalDetails/CustomQuestionBasedByField'
import useAdditionalDetails from '@galileo/composables/useAdditionalDetails'
import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch'
import useRecipientAddressFiller from '@galileo/composables/useAddress/useRecipientAddressFiller'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { STREET_TYPES } from '@galileo/constants/streetTypes'
import IndustryEddForm from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/ui/IndustryEddForm.vue'

import { getSepaCompliantValue } from '@galileo/composables/useRecipientHelpers/useRecipientUpdate'
import { sanitizeString } from '@galileo/utilities/sanitizers/sanitizeString.js'

import {
  useAuthStore,
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useAppStore,
  useResourcesStore,
  useCountriesStore,
} from '@galileo/stores'

export default {
  name: 'SendMoneyAdditionalDetails',
  components: {
    AppCardFooter,
    AppCard,
    AppButton,
    AppDynamicForm,
    AppCardHeader,
    AppBackButton,
    AppSpinnerBig,
    AppIcon,
    AppInputCountry,
    AdditionalDetailsHeaderTitle,
    AdditionalDetailsQuestionDescription,
    CustomQuestionBasedByField,
    XeAddressSearch,
    IconExclamationMark,
    AppAlert,
    IndustryEddForm,
  },
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const resourcesStore = useResourcesStore()
    const countriesStore = useCountriesStore()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    let relationship = ''
    let userOccupation = ''

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)
    //router.currentRoute.path)

    // Return the step data to the component
    const activeStep = sendMoneyStore.activeStep

    const goBackOneStep = () => {
      // Go one step back
      router.replace('/send-money/summary')
    }

    const formAdditional = useSendMoneyAdditionalDetailsForm(null)
    const formRecipient = useRecipientForm()
    const setupAdditional = formAdditional.setup
    const formFieldsAdditional = formAdditional.formFields
    const hasFormFieldsAdditional = formAdditional.hasFormFields
    const isFormInvalidAdditional = formAdditional.isFormInvalid
    const formValidationAdditional = formAdditional.formValidation
    const setupRecipient = formRecipient.setup
    const formFieldsRecipient = formRecipient.formFields
    const hasFormFieldsRecipient = formRecipient.hasFormFields
    const isFormInvalidRecipient = formRecipient.isFormInvalid
    const countries = countriesStore.getOfferedCountries
    const recipient = computed(() => sendMoneyStore.getRecipient)
    const riaQuestionField = ref(null)
    const selectedCountry = ref(authStore.userProfile.country)

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: authStore.userProfile.country,
        })
        return result
      })
    )

    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => resourcesStore.getAddressDetails(selectedId))
    )

    const onSelectAddress = (address) => {
      let streetType

      if (['US', 'CA', 'AU', 'NZ'].includes(authStore.userProfile.country)) {
        streetType = extractStreetType(address.street)
      }

      const addressFields = {
        addressLine1: removeDiacritics(address.addressLine1),
        addressLine2: removeDiacritics(address.addressLine2),
        addressLine3: removeDiacritics(address.addressLine3),
        place: address.city,
        postalCode: address.postCode,
        streetName: address.street,
        streetNumber: address.buildingNumber,
        country: address.countryCode,
        state: address.provinceName,
        area: address.district,
        streetType,
      }

      sortedGroupFields.value.forEach((element) => {
        for (const [key, value] of Object.entries(addressFields)) {
          if (element.id === key) {
            element.value = value
          }
        }
      })
    }

    const extractStreetType = (streetName) => {
      if (!streetName) {
        return null
      }

      const streetTypes = STREET_TYPES
      let parts = streetName.split(' ')
      if (parts.length < 0) {
        return null
      }

      // Last part (word) of streetName is the street type
      let streetType = parts[parts.length - 1]

      if (streetType.length === 1 && parts.length >= 3) {
        // Handle the case when the street type contains 2 words e.g. Avenue North (Ave N)
        const streetTypePrefix = parts[parts.length - 2]
        streetType = `${streetTypePrefix} ${streetType}`
      }

      streetName = streetName.replace(streetType, '').trim()
      streetType = streetType.toUpperCase()

      let streetTypeValue = null

      if (streetTypes[streetType]) {
        streetTypeValue = streetTypes[streetType]
      } else {
        for (const key in streetTypes) {
          const value = streetTypes[key]
          if (value && value.toUpperCase() === streetType) {
            streetTypeValue = streetTypes[key]
            break
          }
        }
      }

      return streetTypeValue
    }

    let isAnswerValid = ref(false)

    const enterRiaAnswer = (v) => {
      riaQuestionField.value.value = v
      isAnswerValid.value = false
    }

    const hasQuestions = computed(
      () =>
        hasFormFieldsAdditional.value &&
        (sortedGroupFields.value.length > 0 || formFieldsRecipient.value.length > 0)
    )

    const {
      sortedGroupFields,
      groupIndex,
      isLastGroup,
      isFirstGroup,
      currentGroup,
      isGroupValid,
      goToNextGroup,
      goToPreviousGroup,
      checkEvents,
    } = useAdditionalDetails(
      formFieldsAdditional,
      hasFormFieldsAdditional,
      riaQuestionField,
      isAnswerValid
    )

    const hasBasicEddQuestions = computed(() => {
      return sortedGroupFields.value.some((field) => field.id === 'corpIndustry')
    })

    //////////////////////////////////////////////////////////////////////////
    const setupFields = async () => {
      await setupAdditional(sendMoneyStore.additionalFields)
      await setupRecipient(sendMoneyStore.recipientFields)
    }

    onBeforeMount(async () => {
      await setupFields()
      if (
        !(formAdditional?.formFields.length > 0 || formRecipient?.formFields.length > 0) &&
        !isFormInvalidAdditional &&
        isFormInvalidRecipient
      ) {
        await submit()
      } else if (
        sendMoneyStore.licenceCardNumberMissing &&
        formFieldsAdditional.value.find((field) => field.id === 'idType')
      ) {
        //check if additional fields have idType and only Licecne Card number is missing
        const userProfile = authStore.userProfile

        //check if user have id stored on the profile
        if (userProfile.customer.idDocDetails) {
          //loop through the user object and set the values to the fields and set them disabled
          for (const [key, value] of Object.entries(userProfile.customer.idDocDetails)) {
            const fieldWithValue = formFieldsAdditional.value.find((field) => field.id === key)
            fieldWithValue.value = value
            fieldWithValue.disabled = true
            fieldWithValue.hidden = false
          }
        }
      }
    })

    const loading = ref(false)

    const submitAdditionalDetail = reactive(
      usePromiseLazy((filledAdditionalDetailsFields) =>
        sendMoneyStore.submitAdditionalDetails({
          filledAdditionalDetailsFields,
          isMobileWallet: false,
        })
      )
    )

    const subIndustryId = ref(null)

    const onSubIndustrySelect = (selectedSubIndustryId) => {
      subIndustryId.value = selectedSubIndustryId
    }

    const onSubmitBasicEdd = async () => {
      let nextPage = await submitAdditionalDetail.exec({
        corpSubIndustry: subIndustryId.value,
      })

      if (nextPage === '/send-money/summary/additional-details') {
        groupIndex.value = 0
        await setupFields()
      } else if (nextPage) {
        router.replace(nextPage)
      } else if (submitAdditionalDetail.error) {
        router.replace(submitAdditionalDetail.error)
      }
    }

    const submit = async () => {
      if (isFormInvalidAdditional.value || isFormInvalidRecipient.value) {
        return
      }

      loading.value = true

      try {
        checkEvents()
        const filledAdditionalDetailsFields = {}
        if (hasFormFieldsAdditional.value) {
          for (const formField of formFieldsAdditional.value) {
            let { id, value } = formField
            switch (id) {
              case 'occupation':
                userOccupation = value
                break
              case 'occupationFreeText':
                if (filledAdditionalDetailsFields['occupation'] !== 'OTHERS_6') {
                  value = null
                }
                break
              case 'sourceOfFundsFreeText':
                if (filledAdditionalDetailsFields['sourceOfFunds'] !== 'OTH') {
                  value = null
                }
                break
              case 'relationToBeneficiaryFreeText':
                //if we selected relation to not be other we set to null whatever value we have in the freeText
                if (filledAdditionalDetailsFields['relationToBeneficiary'] !== 'OTHER') {
                  value = null
                } else {
                  value = getSepaCompliantValue(value)
                }
                break
              case 'ria_bene_addressLine1':
                 value = sanitizeString(value)
                 break
            }
            filledAdditionalDetailsFields[id] = filledAdditionalDetailsFields[id] || value
          }
        }

        if (hasFormFieldsRecipient.value) {
          for (const formField of formFieldsRecipient.value) {
            const { id, value } = formField
            switch (id) {
              case 'customerRecipientRelationshipId':
                relationship = value
                break
            }
            filledAdditionalDetailsFields[id] = value
          }
        }

        analyticsStore.track({
          event: SEGMENT_EVENTS.ADDITIONAL_DETAILS_ENTERED,
          traits: {
            relationship: relationship || null,
            requiresOccupation: !userOccupation,
            requiresReason: true, // always true for XE
            requiresRelationship: !relationship,
            transferReason: sendMoneyStore.form?.transferReason,
            userOccupation: userOccupation || null,
          },
        })

        let nextPage = await submitAdditionalDetail.exec(filledAdditionalDetailsFields)

        if (nextPage === '/send-money/summary/additional-details') {
          groupIndex.value = 0
          await setupFields()
        } else if (nextPage) {
          router.replace(nextPage)
        } else if (submitAdditionalDetail.error) {
          router.replace(submitAdditionalDetail.error)
        }
      } catch (ex) {
        appStore.logException('Exception during submitting additional details', ex)
        appStore.messageBoxGenericError()
      }
      loading.value = false
    }

    const goToActivityPage = () => {
      router.push('/Activity')
    }

    const invalidField = ref(false)

    const fieldValidationListener = (isValid) => {
      invalidField.value = !isValid
    }

    return {
      mq: useMediaQuery(),
      formFieldsAdditional,
      isFormInvalidAdditional,
      formValidationAdditional,
      hasFormFieldsRecipient,
      formFieldsRecipient,
      isFormInvalidRecipient,
      sortedGroupFields,
      submit,
      loading,
      activeStep,
      isLastGroup,
      goToNextGroup,
      goToPreviousGroup,
      isFirstGroup,
      currentGroup,
      goToActivityPage,
      isGroupValid,
      enterRiaAnswer,
      $t,
      hasQuestions,
      goBackOneStep,
      onSelectAddress,
      searchPromiseLazy,
      getAddressDetailsPromiseLazy,
      recipient,
      fieldValidationListener,
      invalidField,
      countries,
      selectedCountry,
      hasBasicEddQuestions,
      onSubIndustrySelect,
      onSubmitBasicEdd,
      subIndustryId,
    }
  },
}
</script>

<style scoped>
.card-footer {
  @apply flex flex-col;
}

.content {
  @apply pb-6;
  @apply text-base;
  @apply font-normal;
  @apply text-center;
}

::v-deep .card-header {
  @apply flex items-center;
  .card-header-title {
    @apply text-lg font-medium;
  }
}
.ssnNumber {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}
.input-ssn {
  ::v-deep .input {
    margin-bottom: 0;
  }
}
.back-button {
  width: auto;
}
::v-deep .loading-spinner-container {
  position: relative !important;
}
::v-deep .p-dropdown-items-wrapper {
  width: 100%;
  .country-name-after-icon {
    text-wrap: wrap;
  }
}
.warning-box {
  @apply text-icon-primary font-normal text-sm leading-5;
  color: #313f5b;
  letter-spacing: 0px;
  .exclamation-mark {
    min-width: 18px;
  }
}
</style>
